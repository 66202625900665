import * as React from 'react';
import * as ReactDOM from 'react-dom';

const CardComponent = () => {
  return <div>
    <div>HELLO WORLD</div>
    {/* <h1>{card.question}</h1> */}
    {/* <p>{card.answer}</p> */}
  </div>
};

if (document.readyState === "loading") {
  document.addEventListener('DOMContentLoaded', () => {
    console.log("Document was not ready, loaded now");
    ReactDOM.render(<CardComponent />, document.getElementById('card-component'));
  });
} else {
  console.log("Document was ready, loading immediately");
  ReactDOM.render(<CardComponent />, document.getElementById('card-component'));
}

console.log("Really, anything? 9:27pm.")

export default CardComponent;
